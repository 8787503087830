//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formStartFormData } from '@/api/modular/flowable/formManage'
import { handleTaskStart } from '@/api/modular/flowable/handleTaskManage'
import { draftAdd } from '@/api/modular/flowable/draftManage'
import allCustomForm from '@/views/main/customForm/index.js'
import tracking from '../defenition/tracking'

export default {
  components: {
    tracking
  },
  data() {
    return {
      allCustomFormComps: allCustomForm,
      jsonData: {},
      visible: false,
      confirmLoading: false,
      cardLoading: false,
      processDefinitionId: '',
      spinningLoading: false,
      recordData: {},
      // 是否為自行開發表單
      isCustomForm: false,
      formCompKey: ''
    }
  },
  methods: {
    /**
     * 初始化方法
     */
    open(record) {
      this.recordData = record
      this.processDefinitionId = record.processDefinitionId
      this.formStartFormData(record)
    },
    /**
     * 獲取預加載表單的數據
     */
    formStartFormData(record) {
      this.cardLoading = true
      formStartFormData({ processDefinitionId: record.processDefinitionId }).then(res => {
        this.cardLoading = false
        if (!res.success) {
          this.$message.error(res.message)
          this.returnPage()
          return
        }
        this.isCustomForm = !this.isJSON(res.data)
        if (this.isCustomForm) {
          this.formCompKey = res.data
          this.$nextTick(() => {
            this.$refs.customFormRef.init(record.formData)
          })
          return
        }
        this.jsonData = JSON.parse(res.data)
        if (record.formData != null) {
          setTimeout(() => {
            this.$refs.kfb.setData(JSON.parse(record.formData))
          }, 100)
        }
      })
    },
    /**
     * 判斷字符串是否為 JSON
     */
    isJSON(str) {
      if (typeof str === 'string') {
        try {
          const obj = JSON.parse(str)
          if (typeof obj === 'object' && obj) {
            return true
          } else {
            return false
          }
        } catch (e) {
          return false
        }
      }
    },
    /**
     * 提交表單
     */
    handleSubmit() {
      // 自定義表單提交
      if (this.isCustomForm) {
        this.$refs.customFormRef
          .getDataForSubmit()
          .then(response => {
            const formData = {}
            formData.formData = JSON.stringify(response)
            const params = {}
            params.processDefinitionId = this.processDefinitionId
            params.variables = formData

            this.postSubmit(params)
          })
          .catch(() => {
          })
      } else {
        // 在線設計表單提交
        this.$refs.kfb
          .getData()
          .then(values => {
            const formData = {}
            formData.formData = JSON.stringify(values)
            const subReq = {}
            subReq.processDefinitionId = this.processDefinitionId
            subReq.variables = formData
            this.postSubmit(subReq)
          })
          .catch(() => {
          })
      }
    },
    /*
     * 提交表單 接口調取
     */
    postSubmit(params) {
      this.spinningLoading = true
      handleTaskStart(params).then(res => {
        this.spinningLoading = false
        if (res.success) {
          this.$message.success('發起成功')
          this.returnPage()
        } else {
          this.$message.error('發起失敗：' + res.message)
        }
      })
    },

    /**
     * 存為草稿
     */
    handleApplyed() {
      // 定制化表單存為草稿
      if (this.isCustomForm) {
        this.$refs.customFormRef
          .getDataForDraft()
          .then(response => {
            const params = {
              processDefinitionId: this.recordData.processDefinitionId,
              formJson: JSON.stringify(this.jsonData),
              processName: this.recordData.name || this.recordData.processName,
              category: this.recordData.category,
              categoryName: this.recordData.categoryName,
              formData: JSON.stringify(response)
            }
            this.spinningLoading = true
            this.postDraftAdd(params)
          })
          .catch(() => {
            console.log('驗證未通過，獲取失敗')
          })
      } else {
        // 在線設計表單 存為草稿
        this.$refs.kfb
          .getData()
          .then(values => {
            const params = {
              processDefinitionId: this.recordData.processDefinitionId,
              formJson: JSON.stringify(this.jsonData),
              processName: this.recordData.name === undefined ? this.recordData.processName : this.recordData.name,
              category: this.recordData.category,
              categoryName: this.recordData.categoryName,
              formData: JSON.stringify(values)
            }
            this.postDraftAdd(params)
          })
          .catch(() => {
          })
      }
    },
    /*
     *存為草稿接口調取
     */
    postDraftAdd(params) {
      this.spinningLoading = true
      draftAdd(params)
        .then(res => {
          if (res.success) {
            this.$message.success('存為草稿成功')
            this.returnPage()
          } else {
            this.$message.error('存為草稿失敗：' + res.message)
          }
          this.spinningLoading = false
        })
        .finally(res => {
          this.spinningLoading = false
        })
    },
    /**
     * 返回並清空已生成的表單
     */
    returnPage() {
      const recordId = this.recordData.id
      this.$emit('close', recordId)
      this.jsonData = {}
      this.recordData = {}
    },
    /**
     * 正常返回
     */
    rollbackPage() {
      this.$emit('rollback')
      this.jsonData = {}
      this.recordData = {}
      this.formCompKey = ''
    },
    /**
     * 重置表單
     */
    handleReset() {
      this.isCustomForm ? this.$refs.customFormRef.reset() : this.$refs.kfb.reset()
    }
  }
}
