/**
 * 申請草稿
 *
 * @author yubaoshan
 * @date 2020/9/9 23:40
 **/
import { axios } from '@/utils/request'

/**
 * 申請草稿查詢
 *
 * @author yubaoshan
 * @date 2020/9/9 23:40
 */
export function draftPage (parameter) {
  return axios({
    url: '/flowableDraft/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 添加/編輯申請草稿
 *
 * @author yubaoshan
 * @date 2020/9/9 23:40
 */
export function draftAdd (parameter) {
  return axios({
    url: '/flowableDraft/addOrUpdate',
    method: 'post',
    data: parameter
  })
}

/**
 * 申請草稿刪除，提交成功刪除，和主動刪除
 *
 * @author yubaoshan
 * @date 2020/8/11 20:45
 */
export function draftDelete (parameter) {
  return axios({
    url: '/flowableDraft/delete',
    method: 'post',
    data: parameter
  })
}
